// Customizable Area Start
import React from 'react'
import {
  Box,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  styled,
} from '@material-ui/core'
import * as Yup from 'yup'
import CompleteProfileController, { Props } from './CompleteProfileController'
import { Formik, FormikErrors } from 'formik'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import {
  ButtonCustom,
  TextInputCustom,
} from '../../../components/src/MaterialTheme'
import Header from '../../../components/src/Header'
import SelectLanguage from '../../../components/src/SelectLanguage'
import { warningIcon } from './assets'
import CustomSelect from '../../../components/src/CustomSelect'
import Loader from '../../../components/src/pandaLoader.web'
import { withTranslation } from 'react-i18next'
import {isValidPhoneNumber,parsePhoneNumberFromString, } from "libphonenumber-js"
import CustomCountrySelector from '../../../components/src/CustomCountrySelector'

class CompleteProfile extends CompleteProfileController {
  constructor(props: Props) {
    super(props)
  }

  progileCategories = [this.props.t("Student"), this.props.t("Teacher")];

  phoneRegExp = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
  emailRegex = /^[A-Za-z0-9._-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

  accountProfileSchema = Yup.object({
    firstName: Yup.string().trim(),
    lastName: Yup.string().trim(),
    userName: Yup.string()
      .trim()
      .required(this.props.t('User name is required')),

    studentPhone: Yup.string()
      .required(this.props.t('Student phone number is required'))
      .test(
        'is-valid-phone',
        this.props.t('Phone number is not valid'),
        value => value ? isValidPhoneNumber((`${value}`), this.state.studedntPhoneCountryCode?.attributes?.alpha2) : false
      ),
    parentPhone: Yup.string()
      .required(this.props.t('Parent phone name is required'))
      .test(
        'is-valid-phone',
        this.props.t('Phone number is not valid'),
        value => value ? isValidPhoneNumber((`${value}`), this.state.parentPhoneCountryCode?.attributes?.alpha2) : false
      ),
    parentEmail: Yup.string()
      .trim()
      .matches(this.emailRegex, this.props.t('Email invalid'))
      .email(this.props.t('Email invalid')),
    teacherEmail: Yup.string()
      .trim()
      .matches(this.emailRegex, this.props.t('Email invalid'))
      .email(this.props.t('Email invalid')),
  })

  renderIconShowPassword = (show: boolean) => {
    return show ? (
      <VisibilityOff
        style={{
          width: '18px',
          height: '18px',
          color: '#ccc',
        }}
      />
    ) : (
      <Visibility
        style={{
          width: '18px',
          height: '18px',
          color: '#ccc',
        }}
      />
    )
  }

  handleClose = () => {
    this.setState({ showAlert: false })
  }

  handleGoBack = () => {
    this.props.navigation.goBack()
  }

  renderTextFieldErrorIcon = (isError: boolean) => {
    return isError ? (
      <InputAdornment position="end">
        <IconButton disabled edge="end">
          <img src={warningIcon} width={18} height={18} />
        </IconButton>
      </InputAdornment>
    ) : null
  }

  handlePressKeyDown = () => this.setState({userNameError: ''})

  handleSelectCountry = (item: string) => {
    console.log("item: ",item)
    this.setState({ selectedCountry: item })
    if (item !== 'Romania') {
    const country = this.state.countriesData.find((items:any) => items.attributes.name == item)
      this.getGradesByCountry(country)
      this.setState({
        selectedCity: 'International',
        selectedSchool: '007 School',
        selectedGrade: '',
        countryObject:country,
      })
     
    } else {
      const country = this.state.countriesData.find((items:any) => items.attributes.name == item)
      this.getGradesByCountry(country)
      this.setState({
        selectedCity: '',
        selectedSchool: '',
        selectedGrade: '',
        isDisabeGrade:false,
        countryObject:country,
        isDisableSelectOtherCountries: false,
      })
    }
  }

  handleSelectCity = (item: string) => {
    const selectedCityId =
      this.state.citiesData?.find(
        (city) => city.attributes.name === item
      )?.id || '1'
    this.setState({
      selectedCity: item,
      selectedSchool: '',
      selectedGrade: '',
    })
    this.getSchools(selectedCityId)
  }

  

  handleSelectSchool = (item: string) => this.setState({ selectedSchool: item, selectedGrade: '' })

  handleSelectGrade = (item: string) => this.setState({ selectedGrade: item })

  renderErrorInfo = (isShow: boolean, errorText: string | undefined) => {
    const text = this.state.userNameError || errorText || this.props.t('Please complete the required fields.')
    return isShow && (
      <div className="errorAlert">
        {text}
      </div>
    )
  }
  handlSelectCategory = (item: string) => this.setState({ profileType: item });

  handlePhoneNumberChange=(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,handleChange:any,name:string,country:any)=>{
      const numericValue = e.target.value.replace(/\D/g, ''); 
      const phoneNumber = parsePhoneNumberFromString(numericValue,country?.alpha2)
      if(phoneNumber){
        let formattedNumber = phoneNumber.formatNational();
        formattedNumber = formattedNumber.replace(/^0+/, '');
        handleChange({ target: { name: name, value: formattedNumber } });
      }else{
        handleChange({ target: { name: name, value: numericValue } })
      }

    
  }
  
 getErrors=(errors:FormikErrors<{parentEmail:string,teacherEmail:string,parentPhone:string,studentPhone:string}>)=>{
 return  errors.parentPhone && errors.studentPhone ? "" :errors.parentPhone?? errors.studentPhone 
 }

  onClickSubmitBtn = () => this.setState({ isClickSubmitBtn: true })

  renderForm = () => (
    <Formik
      data-test-id="formik"
      enableReinitialize
      initialValues={{
        firstName: this.state.firstName??'',
        lastName: this.state.lastName??'',
        userName: '',
        studentPhone: '',
        parentPhone: '',
        parentEmail: '',
        teacherEmail: '',
      }}
      validationSchema={this.accountProfileSchema}
      onSubmit={(values) => {
        if (
          this.state.isClickSubmitBtn &&
          (this.state.selectedCountry === '' || 
          this.state.selectedCity === '' ||
          this.state.selectedSchool === '' ||
          this.state.selectedGrade === '')
        ) {
          return
        }
        this.submitProfileAccount({
          firstName: values.firstName,
          lastName: values.lastName,
          parentEmail: values.parentEmail,
          parentPhone: `+${this.state.parentPhoneCountryCode?.attributes?.country_code}${values.parentPhone.trim()}`,
          studentPhone: `+${this.state.studedntPhoneCountryCode?.attributes?.country_code}${values.studentPhone.trim()}`,
          teacherEmail: values.teacherEmail,
          userName: values.userName
        })
      }}
    >
      {({ errors, touched, handleSubmit, handleChange, values }) => (
        <form className="formContainer" onSubmit={handleSubmit}>
          {this.renderErrorInfo(((this.checkTouchedAndErrorInput(touched.userName, errors.userName) ||
            this.checkTouchedAndErrorInput(touched.studentPhone, errors.studentPhone) ||
            this.checkTouchedAndErrorInput(touched.parentPhone, errors.parentPhone) ||
            this.checkTouchedAndErrorInput(touched.parentEmail, errors.parentEmail) ||
            this.checkTouchedAndErrorInput(touched.teacherEmail, errors.teacherEmail) ||
            (this.checkTouchedAndErrorInput(this.state.isClickSubmitBtn, this.state.selectedCountry === ''))) || 
            Boolean(this.state.userNameError)), 
            this.getErrors(errors) )
          }
          <CustomSelect
          list={this.getRolesList()}
          value={this.state.profileType}
          setValue={this.handlSelectCategory}
          placeholder=""
          showSearchInput
          showCategoriesIcon
          disabledItemList={[this.props.t("Teacher")]}
          style={{ marginBottom: '16px' }}
          />
          
          <TextInputCustom
            value={values.firstName}
            onChange={handleChange}
            error={this.checkTouchedAndErrorInput(touched.firstName, errors.firstName)}
            disabled={this.state.isLoading}
            autoComplete="off"
            name="firstName"
            placeholder={this.props.t("First Name (optional)")}
            variant="outlined"
            style={{ marginBottom: '16px' }}
          />
          <TextInputCustom
            value={values.lastName}
            onChange={handleChange}
            error={this.checkTouchedAndErrorInput(touched.lastName, errors.lastName)}
            disabled={this.state.isLoading}
            autoComplete="off"
            name="lastName"
            placeholder={this.props.t("Last Name (optional)")}
            variant="outlined"
            style={{ marginBottom: '16px' }}
          />
          <TextInputCustom
            value={values.userName}
            onChange={handleChange}
            onKeyDown={this.handlePressKeyDown}
            error={this.checkTouchedAndErrorInput(touched.userName, errors.userName) || !!this.state.userNameError}
            disabled={this.state.isLoading}
            autoComplete="off"
            name="userName"
            placeholder={this.props.t("Username")}
            variant="outlined"
            InputProps={{
              endAdornment: Boolean(
                (this.checkTouchedAndErrorInput(touched.userName, errors.userName)) || this.state.userNameError
              ) ? (
                <InputAdornment position="end">
                  <IconButton disabled edge="end">
                    <img src={warningIcon} width={18} height={18} />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
            style={{ marginBottom: '16px' }}
          />
          <TextInputCustom
            value={values.studentPhone}
            data-testid="studentPhone"
            onChange={(e) => {
              this.handlePhoneNumberChange(
                e,
                handleChange,
                'studentPhone',
                this.state.studedntPhoneCountryCode?.attributes)
            }}
            error={this.checkTouchedAndErrorInput(touched.studentPhone, errors.studentPhone)}
            disabled={this.state.isLoading}
            autoComplete="off"
            name="studentPhone"
            placeholder={this.props.t("Student Phone")}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{marginRight:10}}>
                <CustomCountrySelector 
                 showSearchInput
                 data-testid="studentcountryCode"
                 flag={this.state.studedntPhoneCountryCode}
                 list={this.state.countriesData}
                 setValue={ (value:any)=>{
                  this.setState({
                    studedntPhoneCountryCode:value
                  })
                 }}
                />
                <Typography style={{marginLeft:5}}>+{this.state.studedntPhoneCountryCode?.attributes?.country_code}</Typography>
              </InputAdornment>
              ),
              endAdornment: this.checkTouchedAndErrorInput(
                touched.studentPhone, errors.studentPhone
              ) ? (
                <InputAdornment position="end">
                  <IconButton disabled edge="end">
                    <img src={warningIcon} width={18} height={18} />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
            style={{ marginBottom: '16px' }}
          />
        
          <TextInputCustom
            value={values.parentPhone}
            data-testid="parentPhone"
            onChange={(e) => {
              this.handlePhoneNumberChange(
                e,
                handleChange,
                'parentPhone',
                this.state.parentPhoneCountryCode?.attributes
                )
            }}
            error={this.checkTouchedAndErrorInput(touched.parentPhone, errors.parentPhone)}
            disabled={this.state.isLoading}
            autoComplete="off"
            name="parentPhone"
            placeholder={this.props.t("Parent Phone")}
            variant="outlined"

            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{marginRight:10}}>
                <CustomCountrySelector 
                data-testid="parentcountryCode"
                 showSearchInput
                 flag={this.state.parentPhoneCountryCode}
                 list={this.state.countriesData}
                 setValue={ (value:any)=>{
                  this.setState({
                    parentPhoneCountryCode:value
                  })
                 }}
                />
                <Typography style={{marginLeft:5}}>+{this.state.parentPhoneCountryCode?.attributes?.country_code}</Typography>
                </InputAdornment>
              ),
              endAdornment: this.checkTouchedAndErrorInput(
                touched.parentPhone, errors.parentPhone
              ) ? (
                <InputAdornment position="end">
                  <IconButton disabled edge="end">
                    <img src={warningIcon} width={18} height={18} />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
            style={{ marginBottom: '16px' }}
          />
          <TextInputCustom
            value={values.parentEmail}
            onChange={handleChange}
            error={this.checkTouchedAndErrorInput(touched.parentEmail, errors.parentEmail)}
            disabled={this.state.isLoading}
            autoComplete="off"
            name="parentEmail"
            placeholder={this.props.t("Parent Email (optional)")}
            variant="outlined"
            style={{ marginBottom: '16px' }}
          />
          <CustomSelect
          data-test-id="select-country"
            list={this.state.countriesDisplayList}
            value={this.state.selectedCountry}
            setValue={this.handleSelectCountry}
            isError={
              this.state.isClickSubmitBtn &&
              this.state.selectedCountry === ''
            }
            placeholder={this.props.t("Select your country")}
            showSearchInput
          />
          <CustomSelect
            data-test-id="city-select"
            list={this.state.citiesDisplayList}
            value={this.state.selectedCity}
            setValue={this.handleSelectCity}
            isError={
              this.state.isClickSubmitBtn &&
              this.state.selectedCity === ''
            }
            placeholder={this.props.t("Select your city")}
            showSearchInput
            disableSelect={
              this.state.isDisableSelectOtherCountries ||
              this.state.selectedCountry === ''
            }
            style={{ marginTop: '16px' }}
          />
          <CustomSelect
            list={this.state.schoolsDisplayList}
            value={this.state.selectedSchool}
            setValue={this.handleSelectSchool}
            isError={
              this.state.isClickSubmitBtn &&
              this.state.selectedSchool === ''
            }
            placeholder={this.props.t("Select your school")}
            showSearchInput
            disableSelect={
              this.state.isDisableSelectOtherCountries ||
              this.state.selectedCity === ''
            }
            style={{ marginTop: '16px' }}
          />
          <CustomSelect
            list={this.state.gradesDisplayList}
            value={this.state.selectedGrade}
            setValue={this.handleSelectGrade}
            isError={
              this.state.isClickSubmitBtn &&
              this.state.selectedGrade === ''
            }
            data-test-id="grade"
            placeholder={this.props.t("Select your grade")}
            showSearchInput
            disableSelect={
              this.state.isDisabeGrade ||
              (this.state.selectedCountry==="Romania" && this.state.selectedSchool === '')
            }
            style={{ marginTop: '16px' }}
          />
          <TextInputCustom
            value={values.teacherEmail}
            onChange={handleChange}
            error={this.checkTouchedAndErrorInput(touched.teacherEmail, errors.teacherEmail)}
            disabled={this.state.isLoading}
            autoComplete="off"
            name="teacherEmail"
            placeholder={this.props.t("Teacher Email (optional)")}
            variant="outlined"
            style={{ marginTop: '16px' }}
          />
          <ButtonCustom
            disabled={this.state.isLoading}
            variant="contained"
            type="submit"
            style={{ marginTop: '36px' }}
            onClick={this.onClickSubmitBtn}
            data-test-id="create-account"
          >
            {this.props.t("Create Account")}
          </ButtonCustom>
        </form>
      )}
    </Formik>
  )

  render() {
    return (
      <>
       <CompleteProfileContainer>
        <Header showGoBackBtn onGoBack={this.handleGoBack} />
        <Container className="contentWrapper" maxWidth="md">
          <Box className="content">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                className="numberWrapper"
                style={{
                  marginRight: '-2px',
                }}
              >
                1
              </div>
              <div className="lineWrapper" />
              <div
                className="numberWrapper"
                style={{
                  marginLeft: '-2px',
                }}
              >
                2
              </div>
            </div>
            <Typography className="completeProfileTitle">
             {this.props.t("Complete your profile")}
            </Typography>
            <Typography className="completeProfileDesc">
              {this.props.t("And get your personal learning experience and rewards!")}
            </Typography>
            {this.renderForm()}
          </Box>
          <SelectLanguage style={{ marginBottom: '48px', marginTop: '24px' }} />
        </Container>
      </CompleteProfileContainer>
      </>
    )
  }
}
export default withTranslation()(CompleteProfile)

const CompleteProfileContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',

  '& .contentWrapper': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  '& .content': {
    marginTop: '24px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  '& .numberWrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 22,
    width: 22,
    borderRadius: '50%',
    fontFamily: 'Nunito',
    fontSize: '12px',
    fontWeight: '700',
    backgroundColor: '#62CDFF',
    color: '#fff',
    zIndex: 9,
  },

  '& .lineWrapper': {
    backgroundColor: '#62CDFF',
    height: '6px',
    width: '44px',
  },

  '& .completeProfileTitle': {
    fontFamily: 'Nunito',
    fontSize: '22px',
    fontWeight: '900',
    textAlign: 'center',
    marginTop: '32px',
  },

  '& .completeProfileDesc': {
    color: '#666666',
    marginTop: '10px',
    textAlign: 'center',
    fontSize: '16px',
    fontFamily: 'Quicksand',
    fontWeight: '700',
  },

  '& .errorAlert': {
    width: '300px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    backgroundColor: '#FEE2DF',
    color: '#F96855',
    fontSize: '14px',
    fontFamily: 'Quicksand',
    fontWeight: '500',
    marginBottom: '16px',
  },

  '& .formContainer': {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '36px',
    alignItems: 'center',
  },

  '& .snackBarWrapper': {
    width: '300px',
    borderRadius: '4px',
    padding: '6px 16px',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
  },

  '& .snackBarText': {
    marginLeft: '12px',
    fontSize: '16px',
    fontWeight: '500',
    fontFamily: 'Quicksand',
    overflow: 'hidden',
    wordWrap: 'break-word',
  },


  "& .phoneInputClass":{
    fontSize: "16px",
    fontFamily: "Quicksand",
    fontWeight: 500,
    borderRadius: "10px",
    backgroundColor: "rgb(247, 247, 247)",
    width:400,
    height: "40px",
    border: "2px solid var(--Primary-Neutrals-Warm-gray-100, #E6E6E6)",
    marginBottom:"16px !important"
  },
  
  "& .react-tel-input > .flag-dropdown":{
    marginBottom:"16px !important",
    borderRadius:10,
    height: '40px'
  },

  "& .phoneInputDropdown":{
    marginBottom:"16px !important"
  },

  [theme.breakpoints.up('md')]: {
    '& .errorAlert': {
      width: '400px',
    },
  },
}))

// Customizable Area End
